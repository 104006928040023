import React, { Component } from "react"
import { isIOS, isAndroid, isMobile } from "react-device-detect"
import Layout from '../components/layout';
import Content from "../components/r-page/index"

class redirectPage extends Component {
  componentDidMount() {
    if (!isMobile) {
      window.location.replace("/dl")
    }
    if (isIOS) {
      window.location.replace(
        "https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1"
      )
    }
    if (isAndroid) {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.onblix.app"
      )
    }
  }

  render() {
    return (
      <Layout location="">
        <Content />
      </Layout>
    )
  }
}

export default redirectPage