import React from "react"
import { Typography } from "@material-ui/core"
import DownloadsSection from "../downloads-section"

const Content = () => {
  return (
    <div style={{ padding: "150px 0 60px 0" }}>
      <DownloadsSection
        data={
          <Typography variant="h3" color="textPrimary" align="center">
            Download Blix Today
          </Typography>
        }
        direction="column"
      />
    </div>
  )
}

export default Content
